"use client";
import { useSearchParams } from "next/navigation";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import { useEffect, memo } from "react";

declare const window: WindowWithDataLayer;

export default memo(function PageViewEvent({ metadata = {} }: any) {
	const params: any = useSearchParams();
	const paramKeys: any = [];
	const paramValues: any = [];
	for (const v of params?.keys()) {
		paramKeys.push(v);
	}
	for (const val of params?.values()) {
		paramValues.push(val);
	}

	const paramsArr = paramValues.map((item: string, i: number) => {
		return {
			key: paramKeys[i],
			value: item,
		};
	});
	const paramPairs = paramsArr.map((item: any, i: number) => {
		const tempStr = `${item?.key}=${item?.value}`;
		return tempStr;
	});
	if (metadata?.author) {
		metadata.author =
			metadata?.author === "Anónimo" ? "N+ Video" : metadata?.author;
	}
	useEffect(() => {
		if (typeof window.dataLayer !== "undefined") {
			const sessionData = window.sessionStorage.getItem("sessionData");
			if (sessionData !== null) {
				const sDataParsed = JSON.parse(sessionData);
				const prevBlockData =
					sDataParsed.prevBlock && sDataParsed?.blockPosition
						? `${sDataParsed.prevBlock}_p${sDataParsed?.blockPosition}`
						: undefined;
				metadata.countWidget = metadata?.countWidget ?? sDataParsed.countWidget;
				metadata.page_previous = sDataParsed.prevPath;
				metadata.previousBlock = prevBlockData;
				metadata.previousTipoElemento = sDataParsed.prevElemntType;
				metadata.page_location = `${metadata?.page_location}${
					paramPairs?.length
						? "?" + paramPairs?.join("&")?.replace(/\/*$/, "")
						: ""
				}`;
				metadata.page_path = `${metadata?.page_path}${
					paramPairs?.length
						? "?" + paramPairs?.join("&")?.replace(/\/*$/, "")
						: ""
				}`;
			} else {
				metadata.page_location = `${metadata?.page_location}${
					paramPairs?.length
						? "?" + paramPairs?.join("&")?.replace(/\/*$/, "")
						: ""
				}`;
				metadata.page_path = `${metadata?.page_path}${
					paramPairs?.length
						? "?" + paramPairs?.join("&")?.replace(/\/*$/, "")
						: ""
				}`;
				const sessionData = {
					prevPath: `${metadata?.page_location}${
						paramPairs?.length
							? "?" + paramPairs?.join("&")?.replace(/\/*$/, "")
							: ""
					}`,
					countWidget: undefined,
					prevBlock: undefined,
					prevElemntType: undefined,
				};
				window.sessionStorage.setItem(
					"sessionData",
					JSON.stringify(sessionData)
				);
			}
			window.dataLayer.push(metadata);
		}
	}, [metadata, paramPairs]);

	return null;
});
